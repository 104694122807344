import React, { Component } from "react";
import Isotope from "isotope-layout/js/isotope";
import ImagesLoaded from "imagesloaded/imagesloaded";

class ItemGrid extends Component {
  state = {
    activeItem: "*",
  };

  componentDidMount() {
    var imgLoad = new ImagesLoaded(".grid");

    imgLoad.on("progress", function (instance, image) {
      this.iso = new Isotope(".grid", {
        itemSelector: ".grid-item",
        layoutMode: "masonry",
      });
    });
  }
  onFilterChange = (newFilter) => {
    this.setState({ activeItem: newFilter });
    if (this.iso === undefined) {
      this.iso = new Isotope(".grid", {
        itemSelector: ".grid-item",
        layoutMode: "masonry",
      });
    }

    // this.iso.arrange({ filter: newFilter });

    if (newFilter === "*") {
      this.iso.arrange({ filter: `*` });
    } else {
      this.iso.arrange({ filter: `.${newFilter}` });
    }
  };

  onActive = (v) => (v === this.state.activeItem ? "active" : "");
  render() {
    return (
      <div>
        <ul className="list_style portfolio_menu text-center">
          <li
            className={`${this.onActive("*")}`}
            data-wow-delay="0.1s"
            data-filter="*"
            onClick={() => {
              this.onFilterChange("*");
            }}
          >
            ALL
          </li>
          <li
            className={`${this.onActive("professional")}`}
            data-wow-delay="0.3s"
            data-filter="professional"
            onClick={() => {
              this.onFilterChange("professional");
            }}
          >
            Professional
          </li>
          <li
            className={`${this.onActive(`personal`)}`}
            data-wow-delay="0.6s"
            data-filter="personal"
            onClick={() => {
              this.onFilterChange("personal");
            }}
          >
            Personal
          </li>
          {/* <li
            className={`${this.onActive(`market`)}`}
            data-wow-delay="0.8s"
            data-filter="market"
            onClick={() => {
              this.onFilterChange("market");
            }}
          >
            Marketing
          </li> */}
        </ul>

        <div className="grid row">
          <div className="col-md-3 col-sm-6 col-xs-12 grid-item professional">
            <div className="portfolio hover-style">
              <img src={require("../../image/portfolio/boe.png")} alt="" />
              <div className="item-img-overlay">
                <div className="overlay-info text-center">
                  <h6 className="sm-titl">Bank of Englad - Texas</h6>
                  <h6>
                    React, JavaScript, HTML, CSS, Node, Redux, MDBReact, GSAP,
                    GIT.
                  </h6>
                  {/* <p>
                    Built for Bank of England Texas using React JS, JavaScript,
                    CSS3, Redux, Node.js, MDBReact, and GSAP for animations.
                    This was my first industry-level project. As a junior
                    developer working solo, this presented many challenges and
                    many learning opportunities. It provided me the opportunity
                    to take a deep dive into React, Redux, Node, MDBReact, and
                    Git for version control.
                  </p> */}
                  <div className="icons">
                    {/* <a href=".#"><i className="icon_heart_alt"></i></a> */}
                    <a target="blank" href="https://boe-texas.firebaseapp.com/">
                      <i className="icon-magnifying-glass"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-xs-12 grid-item personal">
            <div className="portfolio hover-style">
              <img src={require("../../image/portfolio/dc1.png")} alt="" />
              <div className="item-img-overlay">
                <div className="overlay-info text-center">
                  <h6 className="sm-titl">Dev Connector</h6>
                  <h6>
                    React, JavaScript, HTML, CSS, MUIReact, FireBase, GIT.
                  </h6>
                  {/* <p>
                    Built for myself and fellow devs working on projects in our
                    free time using React.js, Node.js, Express, MUIReact, CSS,
                    and a few npm packages for the frontend. The backend is
                    handled using FireBase. I took inspiration from a Jira board
                    my team used during my day job. Users are able to create a
                    profile and login with FireBase. Data for projects are also
                    stored with FireBase and can be dynamically displayed on a
                    user's dashboard depending on that person's user rights.
                    Users can see which developers are online in real time and
                    add comments under individual tasks. A task can be completed
                    and removed but only by the user who created the task.
                  </p> */}
                  <div className="icons">
                    <a
                      target="blank"
                      href="https://devtracker-b3494.firebaseapp.com/login"
                    >
                      <i className="icon-magnifying-glass"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-6 col-xs-12 grid-item personal">
            <div className="portfolio hover-style">
              <img src={require("../../image/portfolio/ft2.png")} alt="" />
              <div className="item-img-overlay">
                <div className="overlay-info text-center">
                  <h6 className="sm-titl">Finance Tracker</h6>
                  <h6>
                    React, JavaScript, HTML, CSS, Bootstrap, Firebase, GIT.
                  </h6>
                  <div className="icons">
                    <a
                      target="blank"
                      href="https://financetracker-20936.firebaseapp.com/login"
                    >
                      <i className="icon-magnifying-glass"></i>
                    </a>
                    {/* <p>
                      We've all seen the finance tracker. I won't blow any
                      smoke, this one isn't much different. I built this
                      application while learning how to use Firebase and
                      learning the Ant Design Library. It's fairly simple in
                      that users can log in, log out, signup, and add and delete
                      items from their accounts. Users can only view, add, and
                      delete data they have added to their accounts. Although it
                      was a straightforward project it still took me several
                      weeks to complete. Writing the custom hooks, and actions,
                      and learning the Firebase hooks took some time.
                    </p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-6 col-xs-12 grid-item professional">
            <div className="portfolio hover-style">
              <img src={require("../../image/portfolio/cc.png")} alt="" />
              <div className="item-img-overlay">
                <div className="overlay-info text-center">
                  <h6 className="sm-titl">Cherry Coatings</h6>
                  <h6>Wordpress, php, GraphQl, HTML, CSS, JavaScript.</h6>
                  <div className="icons">
                    <a target="blank" href="https://cherrycoatings.com/">
                      <i className="icon-magnifying-glass"></i>
                    </a>
                    {/* <p>
                      We've all seen the finance tracker. I won't blow any
                      smoke, this one isn't much different. I built this
                      application while learning how to use Firebase and
                      learning the Ant Design Library. It's fairly simple in
                      that users can log in, log out, signup, and add and delete
                      items from their accounts. Users can only view, add, and
                      delete data they have added to their accounts. Although it
                      was a straightforward project it still took me several
                      weeks to complete. Writing the custom hooks, and actions,
                      and learning the Firebase hooks took some time.
                    </p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-6 col-xs-12 grid-item professional">
            <div className="portfolio hover-style">
              <img src={require("../../image/portfolio/fbl.png")} alt="" />
              <div className="item-img-overlay">
                <div className="overlay-info text-center">
                  <h6 className="sm-titl">Forbes Barrentine Law</h6>
                  <h6>Wordpress, php, GraphQl, HTML, CSS, JavaScript.</h6>
                  <div className="icons">
                    <a target="blank" href="https://forbesbarrentinelaw.com/">
                      <i className="icon-magnifying-glass"></i>
                    </a>
                    {/* <p>
                      We've all seen the finance tracker. I won't blow any
                      smoke, this one isn't much different. I built this
                      application while learning how to use Firebase and
                      learning the Ant Design Library. It's fairly simple in
                      that users can log in, log out, signup, and add and delete
                      items from their accounts. Users can only view, add, and
                      delete data they have added to their accounts. Although it
                      was a straightforward project it still took me several
                      weeks to complete. Writing the custom hooks, and actions,
                      and learning the Firebase hooks took some time.
                    </p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-6 col-xs-12 grid-item professional">
            <div className="portfolio hover-style">
              <img src={require("../../image/portfolio/js.png")} alt="" />
              <div className="item-img-overlay">
                <div className="overlay-info text-center">
                  <h6 className="sm-titl">Johnson & Son</h6>
                  <h6>Wordpress, php, GraphQl, HTML, CSS, JavaScript.</h6>
                  <div className="icons">
                    <a target="blank" href="https://johnsonandsons.net/">
                      <i className="icon-magnifying-glass"></i>
                    </a>
                    {/* <p>
                      We've all seen the finance tracker. I won't blow any
                      smoke, this one isn't much different. I built this
                      application while learning how to use Firebase and
                      learning the Ant Design Library. It's fairly simple in
                      that users can log in, log out, signup, and add and delete
                      items from their accounts. Users can only view, add, and
                      delete data they have added to their accounts. Although it
                      was a straightforward project it still took me several
                      weeks to complete. Writing the custom hooks, and actions,
                      and learning the Firebase hooks took some time.
                    </p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-6 col-xs-12 grid-item professional">
            <div className="portfolio hover-style">
              <img
                src={require("../../image/portfolio/DevDiscovery.png")}
                alt=""
              />
              <div className="item-img-overlay">
                <div className="overlay-info text-center">
                  <h6 className="sm-titl">DevDiscovery</h6>
                  <h6>
                    React, Express, Node, Redux, Bootstrap, Azure, ChatGPT.
                  </h6>
                  <div className="icons">
                    <a
                      target="blank"
                      href="https://devdiscovery-89baa5c13006.herokuapp.com/"
                    >
                      <i className="icon-magnifying-glass"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ItemGrid;

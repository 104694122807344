import React from "react";
import { Fade, Reveal } from "react-reveal/";

const About = (props) => {
  const myData = props.myData;
  const { aClass } = props;

  return (
    <section className={`${aClass}`} id="about">
      <div className="container">
        <div className="row">
          <div className="col-lg-7 d-flex align-items-center">
            <Fade bottom cascade duration={1000}>
              <div className="about_content">
                <h2 className="t_color">{myData.aboutme}</h2>
                <h6>{myData.role}</h6>
                <p>{myData.aboutdetails}</p>
                <a href="#contact" className="theme_btn active">
                  Contact Me
                </a>
                <a
                  href="https://drive.google.com/file/d/1xKE2bhfTlxGvzuLfFK1hUb20-j8YRB3U/view?usp=drive_link"
                  target="blank"
                  className="theme_btn"
                >
                  Download CV
                </a>
              </div>
            </Fade>
          </div>
          <div className="col-lg-5">
            <div className="about_img">
              <Reveal effect="fadeInRight" duration={1500}>
                <img src={require("../image/headshot.jpg")} alt="" />
              </Reveal>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;

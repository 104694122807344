import React from "react";
import Sectiontitle from "../component/Banner/Sectiontitle";
import Fade from "react-reveal/Fade";
import { FaReact } from "react-icons/fa";
import {
  SiExpress,
  SiNodedotjs,
  SiMongodb,
  SiPostgresql,
  SiPostman,
  SiHtml5,
  SiCss3,
  SiJavascript,
} from "react-icons/si";

const Service = (props) => {
  const { wClass } = props;

  return (
    <section className={`${wClass}`} id="service">
      <div className="container">
        <Sectiontitle
          Title="What I'm Working With"
          TitleP="Some of My Skills."
        />
        <Fade bottom cascade duration={1000}>
          <div className="row">
            <div className="col-lg-4 col-sm-6">
              <div className="work_item wow fadeInUp" data-wow-delay="0.1s">
                <FaReact color="#61DBFB" />
                <h2 className="t_color">React</h2>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="work_item wow fadeInUp" data-wow-delay="0.1s">
                <SiExpress color="#383838" />
                <h2 className="t_color">Express</h2>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="work_item wow fadeInUp" data-wow-delay="0.1s">
                <SiMongodb color="#096b09" />
                <h2 className="t_color">MongoDB</h2>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="work_item wow fadeInUp" data-wow-delay="0.1s">
                <SiNodedotjs color="#3C873A" />
                <h2 className="t_color">Node.js</h2>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="work_item wow fadeInUp" data-wow-delay="0.1s">
                <SiPostgresql color="#4F688A" />
                <h2 className="t_color">Postgres</h2>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="work_item wow fadeInUp" data-wow-delay="0.1s">
                <SiPostman color="#d78f0d" />
                <h2 className="t_color">Postman</h2>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="work_item wow fadeInUp" data-wow-delay="0.1s">
                <SiHtml5 color="#F06529" />
                <h2 className="t_color">HTML</h2>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="work_item wow fadeInUp" data-wow-delay="0.1s">
                <SiCss3 color="#2565AE" />
                <h2 className="t_color">CSS</h2>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="work_item wow fadeInUp" data-wow-delay="0.1s">
                <SiJavascript color="#F0DB4F" />
                <h2 className="t_color">Javascript</h2>
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </section>
  );
};

export default Service;

import React from "react";
import Navbar from "./component/Navbar/Navbar";
import Banner from "./component/Banner/Banner";
import About from "./component/About";
import Service from "./component/Service";
import Portfolio from "./component/Portfolio/Portfolio";
import MapContainer from "./component/Map";
import Contact from "./component/Contact";
import Footer from "./component/Footer";
import myData from "./component/myData";

export const Home = () => (
  <div className="body_wrapper">
    <Navbar
      mContainer="custome_container"
      mainlogo="logo2.png"
      stickylogo="logo.png"
    />
    <Banner myData={myData} />
    <About aClass="about_area" myData={myData} />
    <Service wClass="work_area" myData={myData} />
    <Portfolio />
    {/* <MapContainer /> */}
    <Contact myData={myData} />
    <Footer myData={myData} />
  </div>
);

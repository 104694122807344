import React, { useState } from "react";
import emailjs from "emailjs-com";
import { Circles } from "react-loader-spinner";

const Form = () => {
  const state = {
    errors: {
      name: "",
      email: "",
    },
  };

  const [emailState, setEmailState] = useState(state);
  const [isLoading, setIsLoading] = useState(false);

  let handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    let errors = emailState;

    switch (name) {
      case "name":
        errors.name = value.length === 0 ? "Name is not empty" : "";
        break;
      case "email":
        errors.email = value.length < 5 ? "Subject is not empty" : "";
        let appos = value.indexOf("@");
        let dots = value.lastIndexOf(".");

        if (appos < 1 || dots - appos < 2) {
          errors.email = "please enter valid email";
        }

        break;

      default:
        break;
    }
    setEmailState({ errors, [name]: value });
  };

  let submitHandler = (e) => {
    setIsLoading(true);
    e.preventDefault();
    emailjs
      .sendForm(
        "service_0oql5rl",
        "template_1dk5s2r",
        e.target,
        "UpBN0IWb6iVQFzUiy"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert(
            "Thank you for reaching out! I will be in touch with you shortly."
          );
          return setIsLoading(false);
        },
        (error) => {
          console.log(error.text);
          alert("form is invalid");
          return setIsLoading(false);
        }
      );
  };

  const { errors } = state;
  return (
    <form onSubmit={submitHandler} className="form_class">
      <div className="row">
        <div className="col-lg-6">
          <input
            type="text"
            id="name"
            name="name"
            className="form-control"
            placeholder="Name"
            onChange={handleChange}
          />
          <p>{errors.name}</p>
        </div>
        <div className="col-lg-6">
          <input
            type="email"
            className="form-control"
            id="email"
            name="email"
            placeholder="Email"
            onChange={handleChange}
          />
          <p>{errors.email}</p>
        </div>
      </div>
      <textarea
        name="message"
        id="message"
        className="form-control"
        rows="6"
        placeholder="Message"
        onChange={handleChange}
      ></textarea>
      {isLoading && (
        <Circles
          height="40"
          width="40"
          color="#4fa94d"
          ariaLabel="circles-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      )}
      <button type="submit" className="btn send_btn theme_btn">
        Send Message
      </button>
    </form>
  );
};

export default Form;

import React from "react";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";
import { RiGithubLine } from "react-icons/ri";
import { TiSocialLinkedin } from "react-icons/ti";
import { ColorRing } from "react-loader-spinner";

const Footer = () => {
  let date = new Date().getFullYear();
  return (
    <section className="footer-area">
      <div className="container">
        <div className="footer-content">
          <Link to="/" className="logo wow fadeInDown" data-wow-delay="0.4s">
            <img src={require("../image/logo2.png")} alt="" />
          </Link>
          <Fade top cascade>
            <ul className="list_style">
              <li>
                <a target="blank" href="https://github.com/MrR-o-b-o-t">
                  <RiGithubLine />
                </a>
              </li>
              <li>
                <a
                  target="blank"
                  href="https://www.linkedin.com/in/sam-k-64455416a/"
                >
                  <TiSocialLinkedin />
                </a>
              </li>
            </ul>
          </Fade>
          <p>© {date} SamKautz</p>
        </div>
      </div>
    </section>
  );
};

export default Footer;

import React, { Component } from "react";
// import { Parallax } from "react-parallax/";
import { Fade, Reveal } from "react-reveal/";
import { RiGithubLine } from "react-icons/ri";
import { TiSocialLinkedin } from "react-icons/ti";

class Banner extends Component {
  render() {
    let myData = this.props.myData;
    return (
      <section id="home">
        <div className="banner_area">
          <div className="container">
            <div className="banner_content">
              <Reveal effect="fadeInUp">
                <h5>HELLO</h5>
              </Reveal>
              <Reveal effect="fadeInUp" duration={1500}>
                <h2>I'm {myData.name}</h2>
              </Reveal>

              <h4 className="wow fadeInUp anoimated">Full-Stack Developer</h4>
              <Fade bottom cascade duration={1000}>
                <ul className="list_style social_icon">
                  <li>
                    <a target="blank" href="https://github.com/MrR-o-b-o-t">
                      <RiGithubLine />
                    </a>
                  </li>
                  <li>
                    <a
                      target="blank"
                      href="https://www.linkedin.com/in/sam-k-64455416a/"
                    >
                      <TiSocialLinkedin />
                    </a>
                  </li>
                </ul>
              </Fade>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Banner;
